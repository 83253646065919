<template>
    <div>
        <div class="row data-form" v-if="defineForm">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="campus_id" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('campus')">
                                    <campus-selectbox :validateError="errors[0]" v-model="defineForm.campus_id">
                                    </campus-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="building_id" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('building')">
                                    <building-selectbox :campus_id="defineForm.campus_id" :validateError="errors[0]"
                                        v-model="defineForm.building_id">
                                    </building-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="classroom_id" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('classroom')">
                                    <classroom-selectbox :building_id="defineForm.building_id" :validateError="errors[0]"
                                        v-model="defineForm.classroom_id">
                                    </classroom-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="nationality_option" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('nationality_option')">
                                    <b-form-select v-model="defineForm.nationality_option"
                                        :options="nationOptions"></b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="rank" rules="required|numeric" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('rank')">
                                    <b-form-input v-model="defineForm.rank"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="capacity" rules="required|numeric" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('capacity')">
                                    <b-form-input v-model="defineForm.capacity"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="observers" rules="" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('observer')">
                                    <multi-selectbox v-model="defineForm.observers" :options="observers">
                                    </multi-selectbox>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>

                        </div>


                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{ $t('save') }}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox";
import PlacementExamClassroomsService from "@/services/PlacementExamClassroomsService";

export default {
    name: "PlacementExamClassroomsUpdateForm",
    components: {
        ValidationProvider,
        ValidationObserver,
        CampusSelectbox,
        BuildingSelectbox,
        ClassroomSelectbox
    },
    props: {
        id: {
            default: null,
            type: Number
        },
        observers: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            defineForm: null,
            nationOptions: [],
        }
    },
    metaInfo() {
        return {
            title: this.$t("placement_exam_classrooms_title")
        }
    },
    methods: {
        getFormData() {
            PlacementExamClassroomsService.show(this.id)
                .then(response => {
                    this.defineForm = response.data.data;
                    this.defineForm.observers = this.defineForm.observers.map(item => item.user_id)
                })
        },

        async sendForm() {
            if (this.checkPermission('placementexamclassroom_update')) {
                const isValid = await this.$refs.updateForm.validate();
                if (isValid) {
                    let formData = {
                        ...this.defineForm
                    }

                    PlacementExamClassroomsService.update(this.id, formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('hide')
                    }).catch(e => {
                        this.showErrors(e);
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },

        setNationOptions() {
            this.nationOptions = [
                { value: null, text: this.$t('select') },
                { value: "TR", text: "TR" },
                { value: "YU", text: "YU" },
                { value: "ALL", text: "ALL" }
            ]
        }
    },

    created() {
        this.setNationOptions()
    },

    watch: {
        id: {
            handler: function (val) {
                this.getFormData(val)
            }
        }
    }
};
</script>
