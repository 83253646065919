import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/placement/exam/classrooms', config);
}

const getPlacementExam = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/placement/exams/' + id);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/placement/exam/classrooms', formData);
}

const remove = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/placement/exam/classrooms/' + id);
}

const show = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/placement/exam/classrooms/' + id);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/placement/exam/classrooms/' + id, formData);
}
const examReportList = async (id, classroomId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('placement/exam/classroom-report/' + id + '/'+ classroomId,{ responseType: 'arraybuffer' });
}


export default {
    getAll,
    getPlacementExam,
    store,
    remove,
    show,
    update,
    examReportList
}
