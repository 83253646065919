<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-xs-12 col-6">
                            <div class="row">
                                <div class="col-12">
                                    <ValidationProvider name="campus" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('campus')">
                                            <campus-selectbox
                                                :validateError="errors[0]"
                                                v-model="defineForm.campusId">
                                            </campus-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12">
                                    <ValidationProvider name="building" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('building')">
                                            <building-selectbox
                                                :campus_id="defineForm.campusId"
                                                :validateError="errors[0]"
                                                v-model="defineForm.buildingId">
                                            </building-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12">
                                    <ValidationProvider rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('nationality_option')">
                                            <b-form-select
                                                v-model="defineForm.nationality_option"
                                                :options="nationOptions"></b-form-select>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <b-form-group>
                                        <b-button variant="outline-primary" @click="addClassroom">{{ $t('add') }}
                                        </b-button>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-6">
                            <b-form-group :label="$t('classroom')">
                                <div class="checkbox-div">
                                    <b-form-group>
                                        <b-input v-model="filterInput" class="mb-2"></b-input>
                                        <div class="university-checkbox-list">
                                            <b-form-checkbox-group
                                                v-model="defineForm.classroomIds"
                                                :options="filteredData"
                                            >
                                            </b-form-checkbox-group>
                                        </div>
                                    </b-form-group>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="classrooms.length>0">
                            <table class="table table-bordered table-striped">
                                <tr>
                                    <th class="width-100">{{ $t('classroom') }}</th>
                                    <th class="width-100">{{ $t('nationality_option') }}</th>
                                    <th class="width-100">{{ $t('rank') }}</th>
                                    <th class="width-100">{{ $t('capacity') }}</th>
                                    <th></th>
                                    <th class="width-100"></th>
                                </tr>
                                <tr v-for="classroom in classrooms" v-bind:key="classroom.id">
                                    <td>{{ classroom.name }}</td>
                                    <td>
                                        <ValidationProvider name="nationality_option" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-select
                                                v-model="classroom.nationality_option"
                                                :options="nationOptions"></b-form-select>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider name="rank" rules="required|numeric"
                                                            v-slot="{valid, errors}">
                                            <b-form-input type="number" step="1"
                                                          v-model="classroom.rank"
                                                          @change="rankChanged"
                                                          :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider name="capacity" rules="required|numeric"
                                                            v-slot="{valid, errors}">
                                            <b-form-input type="number" step="1"
                                                          v-model="classroom.capacity"
                                                          :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider name="observers" rules=""
                                                            v-slot="{valid, errors}">
                                            <multi-selectbox v-model="classroom.observers" :options="observers">

                                            </multi-selectbox>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <b-button class="text-danger" variant="link" size="xs"
                                                  @click="deleteClassroom(classroom.id)"
                                        >
                                            {{ $t('delete').toUpper() }}
                                        </b-button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-5" v-if="classrooms.length>0">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{ $t('save') }}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<style type="scss">
.checkbox-div {
    border: 1px solid #e9ecef;
    border-radius: .375rem;
    height: 280px;
    padding: 10px;
    overflow-x: auto;
}
</style>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
import ClassroomService from "@/services/ClassroomService";
import PlacementExamClassroomsService from "@/services/PlacementExamClassroomsService";
import qs from "qs";
import StaffService from "@/services/StaffService";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";

export default {
    components: {
        MultiSelectbox,
        ValidationProvider,
        ValidationObserver,
        CampusSelectbox,
        BuildingSelectbox,
    },

    props: {
        placement_exam_id: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            defineForm: {
                campusId: null,
                buildingId: null,
                classroom: null,
                nationalityOption: null,
                rank: null,
                capacity: null, classroomIds: undefined

            },

            nationOptions: [],
            classroomItems: [],
            classrooms: [],
            filterInput: '',
            observers:[]
        }
    },
    metaInfo() {
        return {
            title: this.$t("placement_exam_classrooms_title")
        }
    },
    methods: {

        addClassroom() {
            if (this.defineForm.classroomIds.length === 0) {
                return false;
            }

            let rank = this.classrooms.length + 1;
            this.defineForm.classroomIds.forEach(id => {
                if (this.getOptionItem(this.classrooms, id)) {
                    return;
                }
                let item = this.getOptionItem(this.classroomItems, id);
                item.classroom_id = id;
                item.capacity = item.exam_capacity;
                item.nationality_option = this.defineForm.nationality_option;
                item.rank = rank++;
                item.observers=[];
                this.classrooms.push(item);
            })
        },
        deleteClassroom(id) {
            this.classrooms = this.classrooms.filter(function (item) {
                return item.id != id;
            });
        },
        rankChanged() {
            this.classrooms.sort((a, b) => a.rank > b.rank ? 1 : -1);
            for (let i = 0; i < this.classrooms.length - 1; i++) {
                if (this.classrooms[i].rank === this.classrooms[i + 1].rank) {
                    this.classrooms[i + 1].rank++;
                }
            }
        },
        async sendForm() {
            if(this.checkPermission('placementexamclassroom_store')){
                const isValid = await this.$refs.updateForm.validate();
                if (isValid) {
                    /*
                    this.classrooms.forEach(item=>{
                        item.observers=item.observers.map(o=>{
                            return o.value;
                        });
                    });
                    */

                    let formData = {
                        placement_exam_id: this.placement_exam_id,
                        classrooms: this.classrooms,
                        paramsSerializer: (classrooms) => qs.stringify(classrooms, {encode: false})
                    }

                    this.$swal({
                        title: this.$t('attention'),
                        text: this.$t('placement_exam_classroom_store_confirm_text'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    }).then(response => {

                        PlacementExamClassroomsService.store(formData).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.$emit('hide')
                            this.defineForm = {
                                campusId: null,
                                buildingId: null,
                                classroom: null,
                                nationalityOption: null,
                                rank: null,
                                capacity: null
                            }
                            this.classrooms=[];
                            this.classroomItems=[];
                            this.$refs.updateForm.reset();
                        }).catch(e => {
                            if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                                if (e.data.message) {
                                    this.$refs.updateForm.errors.campus.push(this.$t('api.' + e.data.message));
                                    this.$toast.error(this.$t('api.' + e.data.message));
                                }
                            } else {
                                if (e.data.errors["classrooms.0.nationality_option"]) {
                                    this.$refs.updateForm.errors.nationalityOption.push(e.data.errors["classrooms.0.nationality_option"][0]);
                                }
                            }
                        })
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        setNationOptions() {
            this.nationOptions = [
                {value: null, text: this.$t('select')},
                {value: "TR", text: "TR"},
                {value: "YU", text: "YU"},
                {value: "ALL", text: "ALL"}
            ]
        }
    },

    created() {
        this.setNationOptions()
        StaffService.byRole('P').then(res=>{
            res.data.data.forEach(item=>{
                this.observers.push({value: item.user_id, text: item.name+' '+item.surname});
            })
        });
    },

    watch: {
        'defineForm.buildingId': {
            handler: function (val) {
                if (val == null) {
                    return;
                }

                let config = {
                    params: {
                        limit: -1,
                        filter: {
                            building_id: val
                        },
                        sort: 'name'
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                ClassroomService.getAll(config)
                    .then(response => {
                        this.classroomItems = [];
                        response.data.data.forEach(item => {
                            item.value = item.id;
                            item.text = item.name;
                            this.classroomItems.push(item);
                        })
                    });
            }
        }
    },
    computed: {
        filteredData() {
            return this.classroomItems.filter(item => {
                return item.name.toLowerCase().indexOf(this.filterInput.toLowerCase()) > -1
            })
        },
    }
};
</script>
